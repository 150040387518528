import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MainTestService } from "src/app/services/mainTest.service";
import { TokenService } from "src/app/services/token.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
  providers: [MainTestService],
})
export class AuthComponent implements OnInit {
  typesTest = [];
  errWindow = false;
  loading = false;
  showError = false;
  serverMessage: any;
  form: FormGroup;
  authTypes: any;
  havePassEnter: any;
  haveBiometryEnter: any;
  uqcode: string = "";
  showPassEnter: any;
  showBiometryEnter: any;
  pcdata: any = { data: 1 };
  isReg = false;
  placeNum: any;
  isComputerRegistrationRequired = false;

  showAuthFields = false;
  showSemiRegFields = false;
  showRegFields = false;
  showRegData = false;
  isRegVisible = false;
  port: any;
  portsToScan = [];

  portScanResults: any = [];

  constructor(
    private mainTest: MainTestService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    sessionStorage.setItem("showlang", "1");

    sessionStorage.setItem(
      "date",
      this.route.snapshot.queryParamMap.get("kiosk")
    );

    this.uqcode = sessionStorage.getItem("date");

    // this.mainTest.checkMachine(this.uqcode)
    //       .subscribe((res: any) => {
    //         if(res.data == undefined){
    //           this.isReg = false;
    //           sessionStorage.setItem('building', '0');
    //           sessionStorage.setItem('obl', '0');
    //           sessionStorage.setItem('placeNum', '0');
    //           sessionStorage.setItem('room', '0');
    //         }else{
    //           this.loading = false;
    //           this.isReg = true;
    //           this.pcdata = res;
    //           sessionStorage.setItem('building', res.data.building.id);
    //           sessionStorage.setItem('obl', res.data.obl.id);
    //           sessionStorage.setItem('placeNum', res.data.placeNum);
    //           sessionStorage.setItem('room', res.data.room.id);
    //           this.placeNum = this.addLeadingZeros(this.pcdata.data.placeNum, 3)
    //         }
    //       },(err: any) => {
    //         this.loading = false;
    //     });

    // this.getAllTestTypes();
    this.createForm();
  }

  addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, "0");
  }

  // Создание формы
  private createForm(): void {
    this.form = new FormGroup({
      testTypeId: new FormControl(0, [Validators.required]),
      studentId: new FormControl("", [Validators.required]),
     password: new FormControl('', [Validators.required]),
      iin: new FormControl(""),
    });
  }
  get testTypeId() {
    return this.form.get("testTypeId");
  }
  get studentId() {
    return this.form.get("studentId");
  }
  get password() {
    return this.form.get("password");
  }
  get iin() {
    return this.form.get("iin");
  }

  // Авторизация
  submitForm() {
    let obj = {
      username: this.form.get("studentId").value,
      password: this.form.get('password').value,
    };
    this.loading = true;
    this.mainTest.auth(obj).subscribe(
      (res: any) => {
        this.loading = false;
        sessionStorage.setItem(
          "testTypePrefix",
          res.data.specificDataServiceAddressPrefix
        );
        sessionStorage.setItem("testTypeGroupId", res.data.testTypeGroupId);
        if (this.tokenService.authorizeToken(res.data.token)) {
          if (res.path.pathTypeId == 1) {
            window.location.href = window.location.origin + "/" + res.path.path;
          }
          //     else{
          //     window.location.href = res.path.path + '?token=' + res.data.token;
          //   }
        }
      },
      (err: any) => {
        this.loading = false;
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      }
    );
  }

  submitFormBio() {
    this.loading = true;
    console.log(this.form.get("iin").value);
    sessionStorage.setItem("iin", this.form.get("iin").value);
    this.mainTest
      .authBio(this.form.get("testTypeId").value, this.form.get("iin").value)
      .subscribe(
        (res: any) => {
          // console.log(res);
          if (res.data.isExists) {
            sessionStorage.setItem("local_url", window.location.origin);
            sessionStorage.setItem("url_for_check", "/agreement");
            sessionStorage.setItem("iin", this.form.get("iin").value);
            sessionStorage.setItem("studentId", res.data.studentTestId);
            sessionStorage.setItem("endTest", "0");
            sessionStorage.setItem("testOrgId", res.data.testOrgId);
            sessionStorage.setItem("testRoomNum", res.data.testRoomNum);
            sessionStorage.setItem(
              "testRoomPlaceNum",
              res.data.testRoomPlaceNum
            );
            sessionStorage.setItem(
              "wait_verify",
              "Тексеруді күтуде / Ожидание верификации"
            );
            sessionStorage.setItem(
              "wait_check_person",
              "Тексеру күтілуде / Ожидание проверки"
            );
            sessionStorage.setItem(
              "look_anfas",
              "Толық бетке қараңыз / Посмотрите в анфас"
            );
            sessionStorage.setItem(
              "look_anfas_full",
              "Алға қарап, жақындаңыз / Посмотрите в анфас и приблизьтесь"
            );
            sessionStorage.setItem(
              "look_right",
              "Оң жаққа қараңыз / Посмотрите направо"
            );
            sessionStorage.setItem(
              "look_right_full",
              "Оң жаққа қарап, жақындаңыз / Посмотрите направо и приблизьтесь"
            );
            sessionStorage.setItem(
              "look_left",
              "Солға қараңыз / Посмотрите налево"
            );
            sessionStorage.setItem(
              "look_left_full",
              "Солға қарап, жақындаңыз / Посмотрите налево и приблизьтесь"
            );
            sessionStorage.setItem("graduation_fc", "Аяқталды / Пройдено");
            sessionStorage.setItem("fault_fc", "Не пройдено / Не пройдено");
            sessionStorage.setItem(
              "absent_head",
              "Кадрда бет жоқ / В кадре нет лица"
            );
            sessionStorage.setItem("many_head", "Бірнеше бет / Несколько лиц");
            sessionStorage.setItem("thank_you", "Рахмет / Спасибо");

            window.location.href = window.location.origin + "/" + res.path.path;
          } else {
            this.loading = false;
            this.errWindow = true;
            if (this.translateService.currentLang == "ru") {
              this.serverMessage = {
                ru: "Тестирование на текущий день не найдено",
              };
              this.showPass();
            } else {
              this.serverMessage = {
                kz: "Ағымдағы күнге тестілеу табылған жоқ",
              };
              this.showPass();
            }
          }
        },
        (err: any) => {
          this.loading = false;
          this.errWindow = true;
          this.serverMessage = err.error.errorMessage;
        }
      );
  }

  showBio() {
    this.showPassEnter = false;
    this.showBiometryEnter = true;
  }

  showPass() {
    this.showPassEnter = true;
    this.showBiometryEnter = false;
  }

  // Получаем список видов тестирования
  private getAllTestTypes(): void {
    this.mainTest.getAll().subscribe(
      (res: any) => {
        if (res.items) {
          this.typesTest = res.items;
        }
      },
      (err: any) => {
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      }
    );
  }

  arr_diff(sessionStorage1, testType) {
    var a = [],
      diff = [];

    for (var i = 0; i < testType.length; i++) {
      a[testType[i]] = true;
    }

    for (var i = 0; i < sessionStorage1.length; i++) {
      if (a[sessionStorage1[i]]) {
        delete a[sessionStorage1[i]];
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  }

  checkCurrentAuthTypes() {
    sessionStorage.setItem("testTypeId", this.form.get("testTypeId").value);
    this.typesTest.forEach((element: any) => {
      if (this.form.get("testTypeId").value == element.id) {
        let ports: any;

        let sessionStorageArray: any = this.portsToScan;

        ports = this.arr_diff(this.portsToScan, element.portsToScan);

        ports.forEach((elem) => {
          sessionStorageArray.push(elem);
        });

        this.portsToScan = sessionStorageArray;

        for (var i = 0; i < ports.length; i++) {
          var s: any = new WebSocket("ws://127.0.0.1:" + ports[i]);

          s.start = performance.now();

          s.port = ports[i];

          let msec = 0;

          let port = ports[i];

          s.onerror = () => {
            msec = Math.trunc(performance.now() - s.start);
            this.portScanResults.push({
              port: port,
              answerWaitingInMillis: msec,
            });
          };
        }

        if (element.availableAuthTypes.length > 1) {
          if (
            element.availableAuthTypes[0].id == 1 &&
            element.availableAuthTypes[1].id == 2
          ) {
            this.havePassEnter = true;
            this.showPassEnter = false;
            this.haveBiometryEnter = true;
            this.showBiometryEnter = true;
            this.authTypes = 2;
          }
        }
        if (
          element.availableAuthTypes.length == 1 &&
          element.availableAuthTypes[0].id == 2
        ) {
          this.havePassEnter = false;
          this.showPassEnter = false;
          this.haveBiometryEnter = true;
          this.showBiometryEnter = true;
          this.authTypes = 1;
        }
        if (
          element.availableAuthTypes.length == 1 &&
          element.availableAuthTypes[0].id == 1
        ) {
          this.havePassEnter = true;
          this.showPassEnter = true;
          this.haveBiometryEnter = false;
          this.showBiometryEnter = false;
          this.authTypes = 1;
        }
        this.isComputerRegistrationRequired =
          element.isComputerRegistrationRequired;
        this.isRegVisible = element.isRegistrationInformationVisible;
        this.checkShowReg(this.isComputerRegistrationRequired);
      }
    });
  }

  checkShowReg(isComputerRegistrationRequired: any) {
    if (isComputerRegistrationRequired == false) {
      if (this.isReg == true) {
        this.showAuthFields = true;
        this.showRegData = true;
      } else {
        this.showAuthFields = true;
        this.showSemiRegFields = true;
        this.showRegFields = false;
      }
    } else {
      if (this.isReg == true) {
        this.showRegData = true;
        this.showAuthFields = true;
      } else {
        this.showRegFields = true;
        this.showRegData = false;
        this.showAuthFields = false;
        this.showSemiRegFields = false;
      }
    }
  }

  // Обработка ошибки
  showErrors(): void {
    this.showError = false;
  }
}
