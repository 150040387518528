import { Component, ElementRef, HostListener, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MainTestService } from 'src/app/services/mainTest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from 'ng-connection-service';
import { listener } from '../../../assets/calculator';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { io } from "socket.io-client";
import { DisplayService } from 'src/app/services/display.service';
// import { Network } from '@ngx-pwa/offline';
declare var $: any;
declare var html2canvas: any;


@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {


  imageIndex = 0;

  studentInfo: any = null; // Получение информации о сдающем тест
  listOfItems: any = null; // Cписок предметов
  timeLeft: number; // Оставшееся время тестирования
  timePassed: number; // Прошедшее время
  finishTest: any = null; //
  audioValue = false; // Наличие аудиофайла
  appealMotivate: FormGroup; // Форма аппеляции по техническим причинам
  appealEditMotivate: FormGroup; // Форма редактирования аппеляции по техническим причинам
  appealEnd: FormGroup; // Форма завершения тестирования
  appealContent: FormGroup; // Форма аппеляции по содержанию задания
  currentTab: number; // Текущий вопрос
  questionItems = []; // Вопросы предмета
  currentTime: string = ''; // Оставшееся время тестирования
  currentPredmetId: number; // Текущий предмет
  predmetArray = []; // Массив списка предметов
  iFrameRoutePrefix: any; // ссылка для обращения к вопросам
  question: any; // Вопрос с 1 вариантом ответа
  contextQuestion: any; // Вопрос с несколькими вариантами ответов
  testEnding = false; // Модальное окно с фомой завершения тестирования
  appealStatus: any; // Статус аппляции вопроса
  appealDescription: any; // Описание аппеляции по технической причине при редактировании
  appealReason: any; // Причина аппеляции по технической причине при редактировании
  nanoCaptchaIsValid = false; // Появление кнопки завершить тестирование после решения примера
  capthcaAnswer: any; // Ответ на пример
  iFrameQuest = []; // Варианты ответов
  checkStatusArr = []; // Проверка выбранного варианта ответа
  answerLetter = []; // Массив букв вариантов ответов
  appealItems = []; // Список причин аппеляции по технической причине
  contentAppealItems = []; // Список причин аппеляции по содержанию задания
  answerType: number;
  toggleMenu = false;
  showCalc = false;
  showMendeleev = false;
  showSoli = false;
  selected = [];
  loading: any;
  serverMessageKz: any;
  notAllAnswers = false;
  checkNumber: any;
  closeTest = false;
  changeNumber = 0;
  predmetCount = [];
  deleteModal = false; // Модальное окно удаления аппеляци по технической причине
  predmetItems = [];
  order = [];
  additionalFilesItems: any;
  showAppealModal = false;
  showEditAppealModal = false;
  showMapModal = false;
  showContentModal = false;
  countArray = [];
  dataImgScreen = '';
  allQuestions: any;
  extraMsg = false;
  audioUrl: any;
  isTimeLimitedCheck: boolean;
  testsInfo: any;
  canAppealCheck: boolean;
  lastQestion: any;
  isConnected = true;
  contextValue = false;
  serverMessage: any;
  errWindow = false;
  soderjanieVoprosa: any;
  loadingText = false;
  loadingContext = false;

  loadingAnswers = false;
  currentPause: number = 0;
  pauseNow = false;
  pauseStart = false;
  pauseEnd = false;
  unorderPause = false;
  faceCheck = false;
  statusCount = 0;
  pauseTimer: any;
  mainTimer: any;
  ping: any;
  reconection: any;
  reportList = [];
  answerId: any;
  showBarcode: any;
  esseContent: any;
  showInAppeal: any;
  showCalcButton: any;
  showMendButton: any;
  showSoliButton: any;

  inBounds = true;
  inBounds2 = true;

  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };


  captchaQuest = [
    {
      quest: '2 + 2',
      answer: 4
    },
    {
      quest: '1 + 2',
      answer: 3
    },
    {
      quest: '3 + 2',
      answer: 5
    }
  ];

  // status: OnlineStatusType; //Enum provided by ngx-online-status
  // onlineStatusCheck: any = OnlineStatusType;
  online = window.navigator.onLine;

  matchingAnswers = [];

  @ViewChild("watermark", { static: false }) watermark: ElementRef;

  isMobile = false;
  isTablet = false;
  isMob = false;


  constructor(private router: Router,
    private route: ActivatedRoute, public sanitizer: DomSanitizer,
    private mainTest: MainTestService, private _notifications: NotificationsService,
    // protected network: Network,
    private connectionService: ConnectionService, public translate: TranslateService,
    private displayService: DisplayService
  ) {

    if (isDevMode()) {
      this.iFrameRoutePrefix = 'https://test.debug.testcenter.kz/'; // Путь для localhost
    }
    if (!isDevMode()) {
      this.iFrameRoutePrefix = '/'; // Путь для боевого
    }
    const isMob = sessionStorage.getItem("isMobile")
    if(isMob) {
      this.isMob = true
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event:any) {
    this.isMobile = this.displayService.isMobile(+window.innerWidth);
  }

  


  loadingContent() {
    return this.loadingContext || this.loadingText || this.loadingAnswers;
  }

  startLoading(itemNum: number, code:number = 0) {
    if (itemNum != this.currentTab) {
      this.loadingText = true;
      this.loadingContext = true;
      this.loadingAnswers = true;
    }

    if(code == 1){
      this.router.navigate(['/test/quest/', this.currentPredmetId, itemNum]);
      
    }
  }


  // Модальное окно с аппеляцией
  appealModal(): void {
    this.appealMotivate.get('reasonTypeId').setValue(0);
    this.appealMotivate.get('description').setValue('');
    this.appealItems = [];
    this.dataImgScreen = '';
    this.showAppealModal = true;
    this.getAppealReason();
    let that = this;
    var offsetHeight = document.getElementById('currentQuest').offsetHeight + 130;
    var offsetWidth = document.getElementById('currentQuest').offsetWidth + 10;

    html2canvas($('#currentQuest')[0], {
      useCORS: true,
      height: offsetHeight,
      width: offsetWidth
    }).then(function (canvas) {
      var canvasImg = canvas.toDataURL("image/jpg");
      that.dataImgScreen = canvasImg;
      that.data.setValue(canvasImg.substring(22));
    });
  }
  // Окно с подтверждением завершения тестирования
  endModal(): void {
    // if(sessionStorage.getItem('endTest') == '0'){
    // sessionStorage.setItem('endTest', '1');
    // window.location.pathname = 'lv-page.html';
    // }else{
    this.testEnding = true;
    this.notAllAnswers = false;
    this.changeNumber = 0;
    this.reportList.push(
      {
        action: 'endModal',
        time: this.currentTime
      }
    );
    sessionStorage.setItem('reportList', '' + this.reportList);

    this.mainTest.listOfItems()
      .subscribe((res: any) => {
        this.predmetCount = res.data.subjects;
        this.listOfItems = res.data;
        // console.log(this.predmetCount);
        this.predmetItems = res.data.subjects[0];
        for (let index = 0; index < this.listOfItems.subjects.length; index++) {
          const element = this.listOfItems.subjects[index];
          let subjectOrder = element['order'];
          this.predmetArray.push(subjectOrder);
        }
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
    this.checkNumber = this.predmetCount.length;
    this.predmetCount.forEach(element => {
      if (element.questionCount == element.answeredQuestionCount) {
        this.changeNumber = this.changeNumber + 1;
      }
    });
    if (this.checkNumber == this.changeNumber) {
      this.notAllAnswers = true;
    }
    // }

  }
  // Модальное окно с апелляцией по содержанию тестового задания
  contentAppealModal(): void {
    this.showContentModal = true;
  }
  // Модальное окно редактирования аппеляции

  appealEditModal(): void {
    let that = this;
    var offsetHeight = document.getElementById('currentQuest').offsetHeight + 130;
    var offsetWidth = document.getElementById('currentQuest').offsetWidth + 10;
    this.dataImgScreen = '';

    this.mainTest.techAppelItems(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        this.appealItems = [];
        if (res.items) {
          this.appealItems = res.items;
        }

        this.mainTest.techAppelContent(this.currentTab, this.currentPredmetId)
          .subscribe((res: any) => {
            this.loading = false;
            if (res.data.reasonTypeId != null) {
              this.appealReason = res.data.reasonTypeId;
              this.appealDescription = res.data.description;
              this.appealEditMotivate = new FormGroup({
                reasonTypeId: new FormControl(this.appealReason),
                description: new FormControl(this.appealDescription),
                testPageImage: new FormGroup({
                  filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
                  data: new FormControl(this.dataImgScreen)
                })
              });
              html2canvas($('#currentQuest')[0], {
                useCORS: true,
                height: offsetHeight,
                width: offsetWidth,
                // x: 350,
                // y: 100
              }).then(function (canvas) {
                var canvasImg = canvas.toDataURL("image/jpg");
                that.dataImgScreen = canvasImg;
                that.appealEditMotivate.get('testPageImage').get('data').setValue(canvasImg.substring(22));
              });

              this.showEditAppealModal = true;
            }

          }, (err: any) => {
            this.errWindow = true;
            this.loading = false;
            this.serverMessage = err.error.errorMessage;
          });
      }, (err: any) => {
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
        this.appealStatus = false;
        this.loading = false;
      });
  }

  nanoCaptchaHandle(): void {
    if (this.appealEnd.get('nanoCaptcha').value == this.capthcaAnswer.answer) {
      this.nanoCaptchaIsValid = true;
    } else {
      this.nanoCaptchaIsValid = false;
    }
  }

  removeCalculator() {
    this.showCalc = false;
    removeEventListener("keydown", listener, false);
  }

  displayCalculator() {
    this.showCalc = true;
    addEventListener("keydown", listener, true);
  }


  changeLang(val: any) {
    if (val == 'kz') {
      sessionStorage.setItem('lang', 'kz');
      this.translate.use('kz');
    } else {
      sessionStorage.setItem('lang', 'ru');
      this.translate.use('ru');
    }
  }




  ngOnInit() {

    this.isMobile = this.displayService.isMobile(+window.innerWidth);

    sessionStorage.setItem('showlang', '0');


    this.ping = setInterval(() => {
      // console.log(ping);
      this.mainTest.timeLeft()
        .subscribe((res: any) => {
          this.timeLeft = +res.data.timeLeft;
          this.statusCount = 0;
        }, (err: any) => {
          // console.log(err.ok);
          if (this.statusCount < 4) {
            this.statusCount = this.statusCount + 1;
          } else {
            clearInterval(this.ping);
            alert('Потеряно соединение с сервером');
            this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
          }
        });

      // console.log(this.statusCount);

    }, 60000);

    // this.soket.setupSocketConnection();



    this.capthcaAnswer = this.captchaQuest[Math.floor(Math.random() * this.captchaQuest.length)];

    this.appealContent = new FormGroup({
      reasonTypeId: new FormControl(''),
      description: new FormControl(''),
      additionalFiles: new FormGroup({
        id: new FormControl(''),
        description: new FormControl(''),
        filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
        data: new FormControl(this.dataImgScreen)
      })
    });
    this.appealEnd = new FormGroup({
      nanoCaptcha: new FormControl(0)
    })



    this.mainTest.testInfo()
      .subscribe((res: any) => {
        this.testsInfo = res.data;
        if (this.testsInfo.studentTestStatusTypeId == 2) {
          this.router.navigate(['/test-rules']);
        } else if (this.testsInfo.studentTestStatusTypeId == 4) {
          this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
        } else if (this.testsInfo.studentTestStatusTypeId == 3) {
          this.router.navigate(['/test/quest/' + this.currentPredmetId + '/' + this.currentTab]);
        } else if (this.testsInfo.studentTestStatusTypeId == 0) {
          this.router.navigate(['/agreement']);
        } else if (this.testsInfo.studentTestStatusTypeId == 1) {
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else if (this.testsInfo.studentTestStatusTypeId == 5) {
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 8) {
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 9) {
          if (res.path.pathTypeId == 2) {
            window.location.href = res.path.path
          } else {
            window.location.href = window.location.origin + '/' + res.path.path
          }
        } else if (this.testsInfo.studentTestStatusTypeId == 7) {
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }
        else if (this.testsInfo.studentTestStatusTypeId == 6) {
          this.router.navigate(['/agreement']);
        }
        if (this.testsInfo.studentTestStatusTypeId == 10 && this.testsInfo.testType.proctoring.hasProctoring == true) {
          this.pauseNow = true;
          this.pauseStart = true;

        }
        if (this.testsInfo.studentTestStatusTypeId == 10 && this.testsInfo.testType.proctoring.hasProctoring == false) {
          this.unorderPause = true;
        }
        this.canAppealCheck = res.data.testType.canAppeal;
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        }
      });


    // Время для таймера
    this.getTimeLeft();

    // Получение информации о сдающем тест
    this.mainTest.studentInfo()
      .subscribe((res: any) => {
        this.studentInfo = res.data;
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });

    // Получение списка предметов
    this.mainTest.listOfItems()
      .subscribe((res: any) => {
        this.predmetCount = res.data.subjects;
        this.listOfItems = res.data;
        // console.log(this.predmetCount);
        // this.showInAppeal = this.predmetCount[this.currentPredmetId-1].showInAppeal;
        this.predmetItems = res.data.subjects[0];
        for (let index = 0; index < this.listOfItems.subjects.length; index++) {
          const element = this.listOfItems.subjects[index];
          let subjectOrder = element['order'];
          this.predmetArray.push(subjectOrder);
        }
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });


    this.route.params.subscribe(paramsId => {
      this.currentPredmetId = paramsId.predId;
      this.currentTab = paramsId.questId;
      if (this.questionItems.length === 0) {
        this.stepHandler(this.currentPredmetId);
      }

      this.appealMotivate = new FormGroup({
        reasonTypeId: new FormControl(''),
        description: new FormControl(''),
        testPageImage: new FormGroup({
          filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
          data: new FormControl(this.dataImgScreen)
        })
      });

      // Получение вопроса и вариантов ответа с выбранным варинтом ответа
      this.mainTest.questionNum(this.currentTab, this.currentPredmetId)
        .subscribe((res: any) => {
          this.loadingText = true;
          this.loadingAnswers = true;
          this.loadingContext = true;
          this.iFrameQuest = [];
          this.matchingAnswers = [];
          this.contextQuestion = '';
          this.audioUrl = '';
          this.checkStatusArr = [];
          this.answerLetter = [];
          this.order = [];
          this.answerType = res.data.type;
          let iFrameRoute = this.iFrameRoutePrefix + res.data.url;
          this.answerId = res.data.id;
          this.showBarcode = res.data.isBarcodeVisible;
          if (res.data.isCalculatorVisible == false) {
            this.showCalc = res.data.isCalculatorVisible;
          }
          if (res.data.isPeriodicTableVisible == false) {
            this.showMendeleev = res.data.isPeriodicTableVisible;
          }
          if (res.data.isSaltSolubilityTableVisible == false) {
            this.showSoli = res.data.isSaltSolubilityTableVisible;
          }
          this.showCalcButton = res.data.isCalculatorVisible;
          this.showMendButton = res.data.isPeriodicTableVisible;
          this.showSoliButton = res.data.isSaltSolubilityTableVisible;

          if (res.data.techAppealExists) {
            this.appealStatus = true;
          } else {
            this.appealStatus = false;
          }
          if (res.data.isAdditionalContentExists && res.data.additionalContent.type === 1) {
            // Контекстаное задание
            let iFrameContext = this.iFrameRoutePrefix + res.data.additionalContent.url;
            this.contextValue = true;

            let data = res.data.additionalContent.content;
            const regex = /<img.*?>/g;

            //this.contextQuestion = data // Содержимое текстового контекстного задания
            this.contextQuestion = data
            this.loadingContext = false;



          }
          else {
            this.contextValue = false;
            this.loadingContext = false;
          }
          if (res.data.isAdditionalContentExists && res.data.additionalContent.type === 2) {
            this.audioValue = true;
            this.audioUrl = this.iFrameRoutePrefix + res.data.additionalContent.url;
          } else {
            this.audioValue = false;
          }

          this.question = res.data.content; // Содержимое вопроса
          this.loadingText = false;
          // console.log(this.loadingText);
          // console.log(this.loading);
          // console.log(this.loadingAnswers);
          // console.log(this.loadingContext);
          // console.log(this.loadingText);


          // Вопрос
          this.mainLoadAnswer(res.data);
          //console.log(res.data, 123);
        }, (err: any) => {
          this.loading = false;
          if (err.status == 401) {
            clearInterval(this.pauseTimer);
            clearInterval(this.mainTimer);
            clearInterval(this.ping);
            this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
            this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
          } else {
            this.errWindow = true;
            this.serverMessage = err.error.errorMessage;
            // if(this.timeLeft <= 0){
            //   let data;
            //   this.mainTest.finishTest(data, 2)
            //     .subscribe((res:any) =>{
            //     },(err: any) =>{
            //       this.loading = false;
            //     });
            //   alert(err.error.errorMessage.ru);
            //   this.router.navigate(['/answer-map']);
            // }
          }
        });
    });
  }

  get filename() { return this.appealMotivate.get('testPageImage').get('filename'); }
  get data() { return this.appealMotivate.get('testPageImage').get('data'); }

  async mainLoadAnswer(data): Promise<any> {
    if (data.type == 3) {
      this.loadingAnswers = false;
      this.esseContent = data.textAnswer.content;
      this.answerId = data.id;
      this.showBarcode = data.isBarcodeVisible;
      sessionStorage.setItem('esseVersion', data.textAnswer.version);
    } else if (data.type == 5) {
      this.matchingAnswers = data.answers;
      for (let index = 0; index < this.matchingAnswers.length; index++) {
        const element = this.matchingAnswers[index];
        this.checkStatusArr.push(element.isSelected); // Выбранный вариант ответа
        this.order.push(element.order);
        this.loadingAnswers = false;
      }
    } else {
      for (let index = 0; index < data.answers.length; index++) {
        const element = data.answers[index];
        // this.loading = true;
        this.iFrameQuest.push(element.content); // Варианты ответов
        this.checkStatusArr.push(element.isSelected); // Выбранный вариант ответа
        this.answerLetter.push(element.letter); // Буква варианта ответа
        this.order.push(element.order);
        this.loadingAnswers = false;

      }
    }

    let text = '';
    for (let i = 0; i < 150; i++) {
      text += 'Пробное тестирование ';
    }
    this.watermark.nativeElement.innerHTML = text;

  }


  sendReport() {

    this.reportList.push({
      action: 'report',
      time: this.currentTime,
      browserInfo: navigator.userAgent
    });
    sessionStorage.setItem('reportList', '' + this.reportList);

    // reportForm.get('reportList').push(123)

    // console.log(this.reportList);


  }

  loadAnswer(element): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainTest.getHtml(this.iFrameRoutePrefix + element.url)
        .subscribe((res: any) => {
          resolve(res);
        }, (err: any) => {
          this.loading = false;
          reject(err);
        });
    })

  }

  getAppealReason(): void {
    this.loading = true;
    // Список причин для аппеляции по тех. причинам
    this.appealItems = [];
    this.mainTest.techAppelItems(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        if (res.items) {
          this.appealItems = res.items;
        }
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      });
  }

  // private getPredmets(): void {
  //   // Получение списка предметов
  //   this.mainTest.listOfItems()
  //     .subscribe((res: any) => {
  //       // this.items = [];
  //       res.data.subjects.forEach(element => {
  //         let obj = {
  //           element: element,
  //           answers: []
  //         }
  //         // this.items.push(obj);
  //       });

  //     },(err: any) => {
  //       this.loading = false;
  //       this.errWindow = true;
  //       this.serverMessage = err.error.errorMessage;
  //     });
  //   }

  editTestParams() {
    this.mainTest.goToEditing()
      .subscribe((res: any) => {
        this.router.navigate(['/' + res.path.path]);
      }, (err: any) => {

      })
  }

  getTimeLeft() {
    this.mainTest.timeLeft()
      .subscribe((res: any) => {
        this.timeLeft = +res.data.timeLeft;
        this.isTimeLimitedCheck = res.data.isTimeLimited;
        if (this.isTimeLimitedCheck) {
          this.timer();
        }
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }

  // Модальное окно с картой ответов
  mapModal(): void {
    this.showMapModal = true;
  }
  hideMapModal(): void {
    this.showMapModal = false;
  }
  hideMendeleevModal(): void {
    this.showMendeleev = false;
  }
  hideSoliModal(): void {
    this.showSoli = false;
  }

  // Следующий предмет
  stepHandler(value: number): void {
    // let predId = this.currentPredmetId;
    // this.showInAppeal = this.predmetCount[predId-1].showInAppeal;
    if (this.toggleMenu){
      this.hideSideMenu()
    }
    this.reportList.push({
      action: 'stepHandler',
      time: this.currentTime
    });
    sessionStorage.setItem('reportList', '' + this.reportList);
    if (value != this.currentPredmetId) {
      this.router.navigate(['/test/quest/' + (+value) + '/' + 1]);
      this.getPredmetQuestions(value); // Список вопросов
    } else {
      this.router.navigate(['/test/quest/' + (+value) + '/' + this.currentTab]);
      this.getPredmetQuestions(value); // Список вопросов
    }

  }
  // Получение информации о заявлении аппеляции
  getAppealStatus(): void {
    this.mainTest.techAppelContent(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }

  // Проверка того подана ли уже аппеляция
  checkAppealStatus(): void {
    this.mainTest.techAppelContent(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        if (res.data.reasonTypeId != null) {
          this.appealReason = res.data.reasonTypeId;
          this.appealDescription = res.data.description;
          this.appealEditMotivate = new FormGroup({
            reasonTypeId: new FormControl(),
            description: new FormControl(),
            testPageImage: new FormGroup({
              filename: new FormControl('appealScreenShot-' + this.currentTab + '-' + this.currentPredmetId + '.jpg'),
              data: new FormControl(this.dataImgScreen)
            })
          });
        }
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
        this.appealStatus = false;
      });
  }
  // Редактировать аппеляцию по тех. причине
  etidAppeal() {
    let data: any;
    data = this.appealEditMotivate.value;
    this.mainTest.putAppeal(this.currentTab, this.currentPredmetId, data)
      .subscribe((res: any) => {
        this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно отредактировано', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті өзгертілді', NotificationType.Success, 5000);
        this.showEditAppealModal = false;
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }
  // Модальное окно вопроса на удаление аппеляции
  appealDeleteModal(): void {
    this.deleteModal = true;
  }
  // Удаление аппеляции по тех. причине
  appealDelete() {
    this.mainTest.deleteAppeal(this.currentTab, this.currentPredmetId)
      .subscribe((res: any) => {
        this.appealStatus = false;
        this.deleteModal = false;
        this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно удалено', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті жойылды', NotificationType.Success, 5000);
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }
  // Получение вопросов предмета
  private getPredmetQuestions(subjectOrder): void {
    this.mainTest.questionItems(subjectOrder)
      .subscribe((res: any) => {
        this.questionItems = [];
        console.log(res.data.questions)
        for (let i = 0; i < res.data.questions.length; i++) {
          const el = res.data.questions[i];
          this.questionItems.push(el);

          this.lastQestion = res.data.questions.length;
        }
      }, (err: any) => {
        this.loading = false;
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      });
  }

  nextQuestion(): void {
    if (!this.online) {
      this.reconection = true;
    }
    this.loadingAnswers = true;
    this.loadingContext = true;
    this.loadingText = true;
    this.router.navigate(['/test/quest/' + (+this.currentPredmetId) + '/' + (+this.currentTab + 1)]);
    this.reportList.push(
      {
        action: 'nextQuestion',
        time: this.currentTime
      }
    );
    sessionStorage.setItem('reportList', '' + this.reportList);
  }
  previousQuestion(): void {
    if (!this.online) {
      this.reconection = true;
    }
    this.loadingAnswers = true;
    this.loadingContext = true;
    this.loadingText = true;
    this.router.navigate(['/test/quest/' + (+this.currentPredmetId) + '/' + (+this.currentTab - 1)]);
    this.reportList.push(
      {
        action: 'previousQuestion',
        time: this.currentTime
      }
    );
    sessionStorage.setItem('reportList', '' + this.reportList);
  }


  // Завершение тестирования
  testEnd(id: any): void {
    this.reportList.push(
      {
        action: 'testEnd',
        time: this.currentTime
      }
    );
    sessionStorage.setItem('reportList', '' + this.reportList);
    if (!this.online) {
      this.reconection = true;
    }
    this.loading = true;

    this.mainTest.finishTest({}, id)
      .subscribe((res: any) => {
        clearInterval(this.pauseTimer);
        clearInterval(this.mainTimer);
        clearInterval(this.ping);
        if (res.path.pathTypeId == 2) {
          window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
        }
        if (this.testsInfo.testType.id == 14 || this.testsInfo.testType.id == 29) {
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.router.navigate(['/answer-map']);
        }
        this.finishTest = res.data;
      }, (err: any) => {
        this.loading = false;
        if (err.status == 401) {
          clearInterval(this.pauseTimer);
          clearInterval(this.mainTimer);
          clearInterval(this.ping);
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
          this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
        } else {
          this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        }
      });
  }

  // finishingTest(){
  //   let message1: string;
  //   this.translate.currentLang == 'ru' ? message1 = 'Решите простой пример: ' + this.capthcaAnswer.quest : message1 = 'Қарапайым мысалды шешіңіз: ' + this.capthcaAnswer.quest;
  //   let testEndQuest = prompt(message1, '0');
  //   if(testEndQuest == this.capthcaAnswer.answer){
  //     this.testEnd();
  //   } 
  //   if(testEndQuest != this.capthcaAnswer.answer && testEndQuest != null){
  //     this.translate.currentLang == 'ru' ? alert('Введен неверный ответ!') : alert('Қате жауап енгізілді!');
  //   }
  // }

  showSideMenu(): void {
    this.toggleMenu = true;
  }
  hideSideMenu(): void {
    this.toggleMenu = false;
  }
  postAppeal(): void {
    this.showAppealModal = false;
    let data: any;
    data = this.appealMotivate.value;
    this.mainTest.postAppeal(this.currentTab, this.currentPredmetId, data)
      .subscribe((res: any) => {
        this.appealStatus = true;
        this.translate.currentLang == 'ru' ? this._notifications.create('Заявление', 'успешно подано', NotificationType.Success, 5000) : this._notifications.create('Өтініш', 'сәтті берілді', NotificationType.Success, 5000);
      }, (err: any) => {
        this.loading = false;
        this.showAppealModal = true;
        this.errWindow = true;
        this.serverMessage = err.error.errorMessage;
      });

  }

  checkAnswerOrder(i: number) {
    this.order.forEach((element, index) => {
      if (element == i) {
        return index;
      }
    })
  }

  // Логика выбора ответов 1 варианта ответов

  // answerSelect(i: number): void {
  //   let obj = {
  //     isSelected: !this.checkStatusArr[i]
  //   };

  //   this.mainTest.selectedAnswer(this.currentPredmetId, this.currentTab, this.order[i], obj)
  //     .subscribe((res: any) => {
  //       this.checkStatusArr.forEach((element, index) => {
  //         this.checkStatusArr[index] = res.data.selectedAnswerOrders.includes(this.order[index]);
  //       });
  //       this.questionItems[+this.currentTab - 1]['selectedAnswers'] = res.data.selectedAnswerChars;
  //     }, (err: any) => {
  //       this.loading = false;
  //       this.errWindow = true;
  //       this.serverMessage = err.error.errorMessage;
  //     });
  // }

  setPause() {

    this.mainTest.startPause(0)
      .subscribe((res: any) => {
        this.unorderPause = true;

      }, (err: any) => {

      });
  }

  breakPause() {
    if (this.testsInfo.testType.hasProctoring) {
      this.faceCheck = true;
      // let  socket = io('/', { query: { studentIIN: this.studentInfo.iin }, path: '/proctoring/socket.io'});

      // socket.on('connect', () => {
      //   this.mainTest.startBiometry(this.studentInfo.iin)
      //     .subscribe((res: any) => {
      //     },(err: any) => {

      //   });     
      // });
      // socket.on('permitEnter', () => {
      this.mainTest.finishPause()
        .subscribe((res: any) => {
          window.location.href = window.location.origin + '/' + 'lv-page.html'
          this.pauseStart = false;
          this.pauseNow = false;
          this.pauseEnd = false;
          this.faceCheck = false;
          this.unorderPause = false;
        }, (err: any) => {

        });


      // });

      // this.mainTest.finishPause()
      //   .subscribe((res: any) => {
      //     this.pauseStart = false;
      //     this.pauseNow = false;
      //     this.pauseEnd = false;
      //     this.faceCheck = false;
      //     this.unorderPause = false;
      // });


    } else {
      this.mainTest.finishPause()
        .subscribe((res: any) => {
          this.pauseStart = false;
          this.pauseNow = false;
          this.pauseEnd = false;
          this.faceCheck = false;
          this.unorderPause = false;
        });
    }

  }


  pauseCounter(): number {
    // console.log(undefined == 1);
    // let index: number;
    for (let i = this.testsInfo.testType.testRequiredPauses.length - 1; i >= 0; i--) {
      // if(this.timePassed < this.testsInfo.testType.testRequiredPauses[i].timeFromStart + this.testsInfo.testType.testRequiredPauses[i].pauseDuration){
      if (this.timePassed >= this.testsInfo.testType.testRequiredPauses[i].timeFromStart) {
        // console.log(this.testsInfo.testType.testRequiredPauses[i].timeFromStart);
        return i;
      }
    }
    // return index;
  }

  //Таймер
  timer(): void {
    sessionStorage.setItem('url_for_check', window.location.pathname)
    // let bioInterval = setInterval(() => {
    //   window.location.pathname = '/lv-page.html'
    // }, 3000000)
    this.pauseTimer = setInterval(() => {
      // this.pauseCounter();
      // console.log(this.pauseCounter());
      // if(this.pauseCounter() != undefined){
      //   console.log(this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart);
      // }
      if (this.timeLeft == 0) {
        // Конец таймера
        this.errWindow = true;
        // this.serverMessage = 'Время тестирования закончилось';
        this.testEnd(2);
      }
      if (this.testsInfo.testType.hasProctoring) {
        this.timePassed = this.testsInfo.fullAllowedTestTime - this.timeLeft;
        // console.log(this.timePassed);
        if (this.pauseCounter() >= 0) {
          if (
            this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart
            // && this.timePassed <= this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration
            // && !this.pauseNow
          ) { // Старт паузы
            this.mainTest.startPause(this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart - this.timePassed + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration)
              .subscribe((res: any) => {
                this.pauseNow = true;
                this.pauseStart = true;
              }, (err: any) => {

              });
          }

          if (this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration) { // Конец паузы
            this.mainTest.finishPause()
              .subscribe((res: any) => {
                this.pauseStart = false;
                this.pauseEnd = true;
                if (this.testsInfo.testType.hasProctoring) {
                  let socket = io('/', { query: { studentIIN: this.studentInfo.iin }, path: '/proctoring/socket.io' });

                  socket.on('connect', () => {
                    this.mainTest.startBiometry(this.studentInfo.iin)
                      .subscribe((res: any) => {
                      }, (err: any) => {

                      });
                  });
                  socket.on('permitEnter', () => {
                    this.pauseEnd = false;
                    this.pauseNow = false;
                  });
                }
              });
          }

          this.timePassed = this.testsInfo.fullAllowedTestTime - this.timeLeft;
          // console.log(this.timePassed);

          // if(this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart){ // Старт паузы
          //   this.mainTest.startPause(this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration)
          //     .subscribe((res: any) => {
          //       this.pauseNow = true;
          //       this.pauseStart = true;
          //     },(err: any) => {

          //     });
          // }

          // if(this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration){ // Конец паузы
          //   this.mainTest.finishPause()
          //     .subscribe((res: any) => {
          //       this.pauseStart = false;
          //       // this.pauseEnd = true;

          //       this.pauseEnd = false;
          //       this.pauseNow = false;


          //     });
          // }
        }
      } else {

        this.timePassed = this.testsInfo.fullAllowedTestTime - this.timeLeft;
        // console.log(this.timePassed);
        if (this.pauseCounter() >= 0) {
          if (
            this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart
            // && this.timePassed <= this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration
            // && !this.pauseNow
          ) { // Старт паузы

            this.mainTest.startPause(this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart - this.timePassed + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration)
              .subscribe((res: any) => {
                this.pauseNow = true;
                this.pauseStart = true;
              }, (err: any) => {

              });
          }
          if (this.timePassed == this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].timeFromStart + this.testsInfo.testType.testRequiredPauses[this.pauseCounter()].pauseDuration) { // Конец паузы
            this.mainTest.finishPause()
              .subscribe((res: any) => {
                this.pauseStart = false;
                // this.pauseEnd = true;

                this.pauseEnd = false;
                this.pauseNow = false;


              });
          }
        }




      }
    }, 1000);
    this.mainTimer = setInterval(() => {

      if (this.timeLeft == 1800) {
        this.mainTest.timeLeft()
          .subscribe((res: any) => {
            this.timeLeft = +res.data.timeLeft;
          });
        this.translate.currentLang == 'ru' ? this._notifications.create('Осталось', '30 минут', NotificationType.Alert, 5000) : this._notifications.create('30', 'минут қалды', NotificationType.Success, 5000);
      } else if (this.timeLeft == 1200) {
        this.mainTest.timeLeft()
          .subscribe((res: any) => {
            this.timeLeft = +res.data.timeLeft;
          });
        this.translate.currentLang == 'ru' ? this._notifications.create('Осталось', '20 минут', NotificationType.Alert, 5000) : this._notifications.create('20', 'минут қалды', NotificationType.Success, 5000);
      } else if (this.timeLeft == 600) {
        this.mainTest.timeLeft()
          .subscribe((res: any) => {
            this.timeLeft = +res.data.timeLeft;
          });
        this.translate.currentLang == 'ru' ? this._notifications.create('Осталось', '10 минут', NotificationType.Alert, 5000) : this._notifications.create('10', 'минут қалды', NotificationType.Success, 5000);
      }
      const minute = 60;
      const hour = minute * 60;
      const day = hour * 24;
      let hours = this.timeLeft % day / hour;
      let minutes = this.timeLeft % hour / minute;
      let seconds = this.timeLeft % minute;
      if (seconds < 10 && minutes > 10) {
        this.currentTime = ~~hours + ':' + ~~minutes + ':' + '0' + ~~seconds;
      } else if (minutes < 10 && seconds < 10) {
        this.currentTime = ~~hours + ':' + '0' + ~~minutes + ':' + '0' + ~~seconds;
      } else if (minutes < 10) {
        this.currentTime = ~~hours + ':' + '0' + ~~minutes + ':' + ~~seconds;
      } else {
        this.currentTime = ~~hours + ':' + ~~minutes + ':' + ~~seconds;
      }
      this.timeLeft = this.timeLeft - 1;
    }, 1000);
  }

  returnPage() {
    let url = sessionStorage.getItem("url");
    if (url != undefined || url != null) {
      window.open(url, "_self");
    } else {
      this.router.navigateByUrl('/');
    }
  }

}
