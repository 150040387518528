import { Component, OnInit } from '@angular/core';
import { HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainTestService } from 'src/app/services/mainTest.service';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { DisplayService } from 'src/app/services/display.service';
declare var html2canvas: any;
declare var $: any;

@Component({
  selector: 'app-answersMap',
  templateUrl: './answersMap.component.html',
  styleUrls: ['./answersMap.component.css'],
})
export class AnswersMapComponent implements OnInit {
  loading = true; // Активация загрузочного экрана
  items = []; // Массив с данными о предметах
  serverMessage: any;
  errWindow = false;
  testScore: any; // Сумма баллов по всем предметам
  studentTestStatusTypeId: any; // Статус тестирования
  itemScore: any = []; // Сумма баллов по одному предмету
  testsInfo: any; // Информация о тестировании
  canAppealCheck: boolean; // Проверка возможности апелляции
  studentInfo: any;
  dataImgScreen = '';
  timeLeft: any;
  mainTimer: any;
  testSubjects = [];
  form: FormGroup;
  currentTime: string = '';
  ping: any;
  statusCount = 0;
  isMobile = false;
  isMob = false;

  constructor(
    private router: Router,
    public sanitizer: DomSanitizer,
    private mainTest: MainTestService,
    public translate: TranslateService,
    private _notifications: NotificationsService,
    public translateService: TranslateService,
    private displayService: DisplayService
  ) {
    const isMob = sessionStorage.getItem("isMobile")
    if(isMob) {
      this.isMob = true
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event:any) {
    this.isMobile = this.displayService.isMobile(+window.innerWidth);
  }


  ngOnInit() {
    this.isMobile = this.displayService.isMobile(+window.innerWidth);

    sessionStorage.setItem('showlang', '1');

    this.items = [];

    this.ping = setInterval(() => {
      this.statusCount = 0;
      if (this.statusCount < 4) {
        this.statusCount = this.statusCount + 1;
      } else {
        clearInterval(this.ping);
        alert('Потеряно соединение с сервером');
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }

    }, 60000);

    // this.timer();

    this.mainTest.testInfo().subscribe((res) => {
      this.mainTest.studentInfo() // Получение информации о сдающем тест
        .subscribe((resp: any) => {
          this.studentInfo = resp.data;

        });
      this.testScore = res.data.testScore;
      this.testsInfo = res.data;
      this.studentTestStatusTypeId = res.data.studentTestStatusTypeId;


      this.studentTestStatusTypeId = res.data.studentTestStatusTypeId;
      if (res.data.studentTestStatusTypeId === 2) {
        // Подтверждены параметры тестирования, переход на правила тестироования
        this.router.navigate(['/test-rules']);
      } else if (res.data.studentTestStatusTypeId === 4) {
        this.startTest();
        this.router.navigate(['/test/quest/' + 1 + '/' + 1]);
      } else if (res.data.studentTestStatusTypeId === 3) {
        this.startTest();
        this.router.navigate(['/test/quest/' + 1 + '/' + 1]);
      } else if (res.data.studentTestStatusTypeId === 0) {
        this.router.navigate(['/agreement']);
      } else if (res.data.studentTestStatusTypeId === 1) {
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      } else if (res.data.studentTestStatusTypeId === 5) {
        if (res.path.pathTypeId == 2) {
          window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
        }
        // При статусе тестирование завершенно проверка возможности апелляции
        // this.canAppealCheck = res.data.testType.canAppeal;
      } else if (res.data.studentTestStatusTypeId === 7) {
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      } else if (res.data.studentTestStatusTypeId === 9) {
        if (res.path.pathTypeId == 2) {
          window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
        }
        this.canAppealCheck = res.data.testType.canAppeal;
      } else if (res.data.studentTestStatusTypeId === 8) {
        if (res.path.pathTypeId == 2) {
          window.location.href = res.path.path + '?token=' + sessionStorage.getItem('token');
        }
        this.canAppealCheck = res.data.testType.canAppeal;
      } else if (this.testsInfo.studentTestStatusTypeId === 6) {
        this.router.navigate(['/content-appeal/quest/1/1']);
      } else if (this.testsInfo.studentTestStatusTypeId === 11) {
        // this.router.navigate(['/answer-map']);
        location.href = this.testsInfo.testType.anketa.url + '?token=' + sessionStorage.getItem('token') + '&returnUrl=' + location.href;
      }



      this.mainTest.listOfItems().subscribe((res: any) => {
        this.testSubjects = res.data.subjects;

        this.form = new FormGroup({
          studentTestId: new FormControl(''),
          iin: new FormControl(''),
          lastname: new FormControl(''),
          firstname: new FormControl(''),
          patronymic: new FormControl(''),
          image: new FormControl(''),
          subjects: new FormArray([])
        })



        // Получение списка предметов
        res.data.subjects.forEach((element) => {
          // Создание объекта с предметами и выбранными ответами
          // if(element.showInCard == true){
          let obj = {
            element: element,
            answers: [],
          };
          this.items.push(obj);
          // }
        });

        let pendingSubjectCount = 0;
        this.items.forEach((element, i) => {
          pendingSubjectCount++;
          this.mainTest
            .questionAnsweredItems(element.element.order)
            .subscribe((data: any) => {
              // Отвеченные вопросы
              this.items[i]['answers'].push(data.data.questions);
              pendingSubjectCount--;
              this.loading = pendingSubjectCount !== 0;
            });
        });
        let prefix = sessionStorage.getItem('testTypePrefix');
        this.loading = false;
      });

    }, (err: any) => {
      if (err.status == 401) {
        this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
        this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
      }
    });



    setTimeout(() => {
      // this.screenShot();
    }, 1000);




  }

  gohome() {

    let url = sessionStorage.getItem("url");
    if (url != undefined || url != null) {
      window.open(url, "_self");
    } else {
      this.router.navigateByUrl('/');
    }


  }

  // timer(time: any): void {
  //   this.timeLeft = time;
  //   this.mainTimer = setInterval(() => {
  //     if(this.timeLeft == 0){
  //       this.router.navigateByUrl('?kiosk=' + sessionStorage.getItem('date'));
  //     }
  //     const minute = 60;
  //     const hour = minute * 60;
  //     const day = hour * 24;
  //     let hours = this.timeLeft % day / hour;
  //     let minutes = this.timeLeft % hour / minute;
  //     let seconds = this.timeLeft % minute;
  //     if(seconds < 10 && minutes > 10){
  //       this.currentTime = ~~hours + ':' + ~~minutes + ':' + '0' + ~~seconds;
  //     }else if(minutes < 10 && seconds < 10){
  //       this.currentTime = ~~hours + ':' + '0' + ~~minutes + ':' + '0' + ~~seconds;
  //     }else if(minutes < 10){
  //       this.currentTime = ~~hours + ':'+ '0' + ~~minutes + ':' + ~~seconds;
  //     }else{
  //       this.currentTime = ~~hours + ':' + ~~minutes + ':' + ~~seconds;
  //     }
  //     this.timeLeft = this.timeLeft - 1;
  //   }, 1000);
  // }

  goToAnketa() {
    location.href = this.testsInfo.testType.anketa.url + '?token=' + sessionStorage.getItem('token') + '&returnUrl=' + location.href;
  }

  get subjects() { return this.form.get('subjects') as FormArray };

  // screenShot(){
  //   let that = this;


  //     var offsetHeight = document.getElementById('results').offsetHeight + 130;
  //     var offsetWidth = document.getElementById('results').offsetWidth + 10;

  //     // console.log(this.testSubjects);

  //     this.testSubjects.forEach(element => {
  //       // console.log(element);
  //       let subjectsForm = new FormGroup({
  //         order: new FormControl(element.order),
  //         name: new FormControl(element.name.ru),
  //         score: new FormControl(element.testScore)
  //       })

  //       // console.log(subjectsForm);
  //         this.subjects.push(subjectsForm);
  //     });

  //     html2canvas($('#results')[0], {
  //       useCORS: true,
  //       height: offsetHeight,
  //       width: offsetWidth,
  //       // x: 350,
  //       // y: 100
  //     }).then(function (canvas) {
  //       var canvasImg = canvas.toDataURL("image/jpg");
  //       that.dataImgScreen = canvasImg;
  //       that.form.get('image').setValue(canvasImg.substring(22));
  //       that.form.get('studentTestId').setValue(that.testsInfo.studentTestId);
  //       that.form.get('lastname').setValue(that.studentInfo.lastname);
  //       that.form.get('firstname').setValue(that.studentInfo.firstname);
  //       that.form.get('patronymic').setValue(that.studentInfo.patronymic);
  //       that.form.get('iin').setValue(that.studentInfo.iin);


  //       // that.form.get('subjects').patchValue(that.testSubjects);
  //       that.mainTest.testResultsImage(that.form.value)
  //         .subscribe((res: any) => {
  //           that.loading = false;

  //       }, (err: any) => {

  //       });
  //       console.log(that.form.value);
  //     });
  // }

  // Начало тестирования
  public startTest(): void {
    this.mainTest.startTest({})
      .subscribe((res: any) => {
        this.router.navigate(['/test/quest/1/1']);
      }
      );
  }

  // goToAppeal(): void {
  //   let data: any;
  //   this.loading = true;
  //   clearInterval(this.mainTimer);
  //   this.mainTest.startAppeal(data).subscribe((res: any) => {
  //     this.loading = false;
  //     // Переход на апелляцию по содержанию задания
  //     this.router.navigate(['/content-appeal/quest/1/1']);
  //   }, (err: any) => {
  //     this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
  //     this.loading = false;
  //   });
  // }

  // superTestEnd(){
  //   let data: any;
  //   this.loading = true;
  //   clearInterval(this.mainTimer);
  //   this.mainTest.startAppeal(data).subscribe((res: any) => {
  //     this.mainTest.endAppeal(data)
  //     .subscribe((res: any) =>{
  //       window.location.reload();
  //       this.loading = false;
  //     }, (err: any) => {
  //       this.translate.currentLang == 'ru' ? this._notifications.create('Ошибка', err.error.errorMessage.ru, NotificationType.Error, 5000) : this._notifications.create('Қате', err.error.errorMessage.kz, NotificationType.Error, 5000);
  //       this.loading = false;
  //     })
  //   });
  // }

  // private getAnswer(): void {
  //   this.items.forEach((element, i) => {
  //     this.itemScore.push(null);

  //     this.mainTest
  //       .questionAnsweredItems(element.element.order)
  //       .subscribe((res: any) => {
  //         this.items[i]['answers'].push(res.data.questions);

  //         res.data.questions.forEach((element) => {
  //           this.itemScore[i] += +element.testScore;
  //         });
  //       });
  //   });
  // }
}
