import { ContentAppealComponent } from './components/content-appeal/content-appeal.component';
import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularDraggableModule } from 'angular2-draggable';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxBarcodeModule } from 'ngx-barcode';
// import { ImageViewerModule } from "ngx-image-viewer";
// import { OfflineModule } from '@ngx-pwa/offline';


import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TestingComponent } from './components/testing/testing.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { TestRulesComponent } from './components/test-rules/test-rules.component';
import { AnswersMapComponent } from './components/answersMap/answersMap.component';
import { HiddenAuthComponent } from './components/hiddenAuth/hiddenAuth.component'; // Компонент для авторизации через редирект
import { apiConfig } from './config/apiRoutes';
import { CalculatorComponent } from './components/testing/calculator/calculator.component';
import { MinimapComponent } from './components/testing/minimap/minimap.component';
import { QuestBlockComponent } from './components/testing/quest-block/quest-block.component';
import { BiometryComponent } from './components/biometry/biometry.component';
import { MendeleevComponent } from './components/testing/mendeleev/mendeleev.component';
import { SoliComponent } from './components/testing/soli/soli.component';
import { MacRegComponent } from './components/mac-reg/mac-reg.component';
import { CustomDropdownComponent } from './components/testing/custom-dropdown/custom-dropdown.component';
import { TextSearchPipe} from './components/testing/custom-dropdown/text-search.pipe';
import { ArrayFilterPipe } from './components/testing/custom-dropdown/filter-by.pipe';
import { LimitToPipe } from './components/testing/custom-dropdown/limit-to.pipe';
import { NotMobileAppComponent } from './components/not-mobile-app/not-mobile-app.component';



const appRoutes: Routes = [
  // { path: '',   redirectTo: '/authorization', pathMatch: 'full' },
  { path: 'authorizationHidden', component: HiddenAuthComponent },
  { path: '', component: AuthComponent },
  { path: 'mac-reg', component: MacRegComponent },
  { path: 'lv-page', pathMatch: 'full', redirectTo: '/lv-page.html' },
  { path: 'aaa', component: CalculatorComponent },
  { path: 'aaaa', component: MendeleevComponent },
  { path: 'agreement', component: AgreementComponent },
  { path: 'answer-map', component: AnswersMapComponent },
  { path: 'not-mobile-app', component: NotMobileAppComponent },

  { path: 'test',
    children: [
      {
        path: 'quest/:predId/:questId',
        component: TestingComponent
      },
        {
          path: '**', component: PageNotFoundComponent
        }
    ]
  },
  { path: 'content-appeal',
    children: [
      {
        path: 'quest/:predId/:questId',
        component: ContentAppealComponent
      },
        {
          path: '**', component: PageNotFoundComponent
        }
    ]
  },
  { path: 'test-rules', component: TestRulesComponent},
  { path: '**', component: PageNotFoundComponent }
]
 

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
   declarations: [
      AppComponent,
      AuthComponent,
      HiddenAuthComponent,
      TestingComponent,
      PageNotFoundComponent,
      AgreementComponent,
      TestRulesComponent,
      AnswersMapComponent,
      ContentAppealComponent,
      CalculatorComponent,
      MinimapComponent,
      QuestBlockComponent,
      BiometryComponent,
      MendeleevComponent,
      SoliComponent,
      MacRegComponent,
      CustomDropdownComponent,
      TextSearchPipe,
      ArrayFilterPipe,
      LimitToPipe,
      NotMobileAppComponent 
   ],
   imports: [
      BrowserModule,
      HttpClientModule,
      FormsModule,
      NgxLoadingModule.forRoot({}),
      ReactiveFormsModule,
      MatPaginatorModule,
      // ImageViewerModule.forRoot(),
      BrowserAnimationsModule,
      NgxBarcodeModule,
      SimpleNotificationsModule.forRoot({
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true
      }),
      MatTabsModule,
      AngularDraggableModule,
      CKEditorModule,
      RouterModule.forRoot(appRoutes),
      TranslateModule.forRoot({
        defaultLanguage: 'kz',
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    // OfflineModule.forRoot({ guardsRedirect: false })
   ],
   providers: [
    apiConfig,
    TestingComponent
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
