import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-not-mobile-app",
  templateUrl: "./not-mobile-app.component.html",
  styleUrls: ["./not-mobile-app.component.scss"],
})
export class NotMobileAppComponent implements OnInit {
  isAndroid = false;
  isIphone = false;
  isOther = false;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.getDeviceType();
  }

  getDeviceType(): void {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
      this.isAndroid = true;
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      this.isIphone = true;
    } else {
      this.isOther = true;
    }
  }

  navigateToApp(isAndroid: boolean = false) {
    if (isAndroid) {
      const url =
        "https://play.google.com/store/apps/details?id=com.testcenter.utodeneme";
      window.location.href = url;
    }else{
      const url =
      "https://apps.apple.com/kz/app/uto-kz/id6464261093";
    window.location.href = url;
    }

  
  }
}
